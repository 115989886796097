<template>
  <b-card bg-variant="light"
          header="Additional sample declarations"
          header-bg-variant="dark"
          header-text-variant="white">
    <additional-sample-declaration-list :elevated="false"
                                        :loadDataUrl="url"
                                        :propFields="fields"/>
  </b-card>
</template>

<script>
  import AdditionalSampleDeclarationList from
  '@/components/proposal/additionalSampleDeclaration/AdditionalSampleDeclarationList';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'AdditionalSampleDeclarationDashboard',
    components: { AdditionalSampleDeclarationList },
    data() {
      return {
        url: '/documents/additional_sample_declarations/me',
        fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'general.end_station',
            label: 'End station',
          },
          {
            key: 'created_at',
            label: 'Proposal creation date',
          },
          {
            key: 'additional_sample_declaration.states.name',
            label: 'Declaration status',
          },
          {
            key: 'actions',
            label: 'Actions',
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
