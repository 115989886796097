<template>
  <div>
    <b-table :current-page="currentPage"
             :fields="fields"
             :items="additionalSampleDeclarations"
             :per-page="perPage"
             head-variant="light"
             hover
             responsive
             striped>
      <template v-if="showFilter"
                v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="additionalSampleDeclarations"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']">
        </proposal-filter>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: perPage, currentPage: currentPage}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-comment v-if="!elevated"
                                  title="Go to additional sample declaration"
                                  @click="goToDeclaration(row.item)"/>
      </template>
    </b-table>
    <b-table-footer v-model="loading"
                    :currentPage="currentPage"
                    :data_length="additionalSampleDeclarations.length"
                    :header-size="footerSize"
                    :pagination="footerPagination"
                    :perPage="perPage"
                    @change_page="changePage">
    </b-table-footer>
  </div>
</template>

<script>
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import bTableFooter from '@/components/b_table_footer';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    name: 'AdditionalSampleDeclarationList',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonComment,
      proposalFilter,
      bTableFooter,
    },
    props: {
      elevated: {
        type: Boolean,
        default: false,
      },
      footerPagination: {
        type: Boolean,
        default: true,
      },
      footerSize: {
        type: Number,
        default: 1,
        validator: (value) => [1, 2, 3, 4, 5, 6].includes(value),
      },
      loadDataUrl: {
        type: String,
        required: true,
      },
      propFields: {
        type: Array,
        default: () => [],
      },
      showFilter: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        id: 1,
        perPage: 20,
        loading: false,
        currentPage: 1,
        additionalSampleDeclarations: [],
        fields: this.propFields,
      };
    },
    async created() {
      await this.loadData();
    },
    methods: {
      changePage(value) {
        this.currentPage = value;
      },
      goToDeclaration(item) {
        this.$router.push(`/documents/${item._id.$oid}/additional-sample-declarations`);
      },
      async loadData() {
        this.loading = true;
        try {
          const response = await this.axios.get(this.loadDataUrl);
          this.additionalSampleDeclarations = this.processDocuments(response.data);
          this.$emit('setup_proposal', this.additionalSampleDeclarations);
          this.loading = false;
        } catch {
          this.loading = false;
          this.$notify({ type: 'error', title: 'Could not load data' });
        }
      },
      processDocuments(documents) {
        const documentsWithCreatedAt = documents.map(
          (document) => ({
            ...document,
            // eslint-disable-next-line camelcase
            created_at: this.created_at(document),
          }),
        );
        const sortKey = 'created_at';
        return documentsWithCreatedAt.sort((b, a) => new Date(a[sortKey]) - new Date(b[sortKey]));
      },
    },
  };
</script>

<style scoped>

</style>
